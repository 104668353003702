import { Button, Divider, Stack } from '@grafana/ui';
import { t } from 'app/core/internationalization';

import ReportSection from '../../ReportSection';

import { SelectDashboardScene } from './SelectDashboardScene';

export default function SelectDashboards({
  dashboards,
  onAddDashboard,
}: {
  dashboards: SelectDashboardScene[];
  onAddDashboard: () => void;
}) {
  return (
    <ReportSection label={t('share-report.dashboard.section-title', 'Dashboard')}>
      <Stack direction="column" gap={2}>
        {dashboards.map((dashboard, index) => {
          return (
            <Stack direction="column" key={dashboard.state.key}>
              <dashboard.Component model={dashboard} />
              {index < dashboards.length - 1 && <Divider spacing={0} />}
            </Stack>
          );
        })}
      </Stack>
      <Divider />
      <Button variant="primary" fill="text" onClick={onAddDashboard}>
        + {t('share-report.dashboard.add-dashboard', 'Add dashboard')}
      </Button>
    </ReportSection>
  );
}
