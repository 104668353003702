import { css } from '@emotion/css';
import { Controller, useFormContext } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, TagsInput, useStyles2 } from '@grafana/ui';
import { t } from 'app/core/internationalization';
import { ReportBaseV2 } from 'app/extensions/types/reports';
import { emailSeparator, isEmail } from 'app/extensions/utils/validators';

import { canEditReport } from '../../utils/permissions';
import { formSchemaValidationRules } from '../ReportForm';
import ReportSection from '../ReportSection';

export default function Recipients() {
  const styles = useStyles2(getStyles);
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useFormContext<ReportBaseV2>();

  function handleTagsChange(tags: string[]) {
    // First, split any tags that contain separators (semicolons or commas)
    const splitTags = tags.flatMap((tag) =>
      tag
        .split(emailSeparator)
        .filter(Boolean)
        .map((email) => email.trim())
    );

    // Remove duplicates using Set
    const uniqueTags = [...new Set(splitTags)];

    const validEmails = uniqueTags.filter((tag) => isEmail(tag));
    const invalidEmails = uniqueTags.filter((tag) => !isEmail(tag));

    // Update form value with array of valid emails
    setValue('recipients', validEmails);

    if (invalidEmails.length) {
      setError('recipients', {
        type: 'manual',
        message:
          invalidEmails.length > 1
            ? t('share-report.recipients.invalid-emails', 'Invalid emails: {{emails}}', {
                emails: invalidEmails.join('; '),
              })
            : t('share-report.recipients.invalid-email', 'Invalid email: {{email}}', {
                email: invalidEmails[0],
              }),
      });
    } else {
      clearErrors('recipients');
    }
  }

  return (
    <ReportSection defaultOpen={true} label={t('share-report.recipients.section-title', 'Recipients')}>
      <Field
        label={t('share-report.recipients.field-label', 'Recipients')}
        description={t(
          'share-report.recipients.tooltip',
          'Separate multiple email addresses with a comma or semicolon'
        )}
        required
        invalid={!!errors.recipients}
        error={errors.recipients?.message}
      >
        <Controller
          name="recipients"
          control={control}
          rules={formSchemaValidationRules().recipients}
          render={({ field: { value, name } }) => (
            <TagsInput
              id={name}
              disabled={!canEditReport}
              invalid={!!errors.recipients}
              onChange={handleTagsChange}
              placeholder={t(
                'share-report.recipients.placeholder',
                'Type in the recipients email addresses and press Enter'
              )}
              tags={value}
              className={styles.tagsInput}
              addOnBlur
            />
          )}
        />
      </Field>
    </ReportSection>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  tagsInput: css({
    li: {
      backgroundColor: `${theme.colors.background.secondary} !important`,
      borderColor: `${theme.components.input.borderColor} !important`,
      color: theme.colors.text.primary,
    },
  }),
});
