import { VariableHide } from '@grafana/data';

import { ReportDashboard } from '../../types';
import { parseRange } from '../../utils/time';
import { SelectDashboardScene } from '../ReportFormV2/sections/SelectDashboards/SelectDashboardScene';

export const dashboardsInvalid = (dashboards?: ReportDashboard[]) => {
  if (!dashboards?.length) {
    return true;
  }

  return !dashboards.some((db) => !!db.dashboard?.uid);
};

export const getDashboardsDTO = (dashboards: SelectDashboardScene[]): ReportDashboard[] => {
  return dashboards.map((d) => {
    const { state } = d;
    const timeRangeValue = state.$timeRange?.state.value;
    return {
      dashboard: {
        uid: state.uid ?? '',
        name: state.title,
      },
      reportVariables:
        state.$variables?.state.variables.reduce((acc: Record<string, string[]>, variable) => {
          if (variable.state.hide !== VariableHide.hideVariable) {
            const value = variable.getValue();
            acc[variable.state.name] = Array.isArray(value) ? value.map(String) : [String(value ?? '')];
          }
          return acc;
        }, {}) ?? {},
      timeRange: parseRange(timeRangeValue),
    };
  });
};
