import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { GrafanaTheme2 } from '@grafana/data';
import { SceneDataLayerControls, VariableValueSelectors } from '@grafana/scenes';
import { Button, Divider, Input, Stack, useStyles2, Field } from '@grafana/ui';
import { t } from 'app/core/internationalization';
import { ReportBaseV2 } from 'app/extensions/types/reports';
import { isEmail } from 'app/extensions/utils/validators';

import { DashboardScene } from '../../../features/dashboard-scene/scene/DashboardScene';
import { DEFAULT_EMAIL_MESSAGE, defaultZoom } from '../constants';
import { selectors } from '../e2e-selectors/selectors';
import { canEditReport } from '../utils/permissions';

import Attachments from './sections/Attachments';
import EmailConfiguration from './sections/EmailConfiguration';
import Recipients from './sections/Recipients';
import Schedule from './sections/Schedule';
import { SelectDashboardScene } from './sections/SelectDashboards/SelectDashboardScene';
import SelectDashboards from './sections/SelectDashboards/SelectDashboards';

const REPORT_FORM_SECTIONS = [
  { id: 'select-dashboards', component: SelectDashboards },
  { id: 'schedule', component: Schedule },
  { id: 'email-configuration', component: EmailConfiguration },
  { id: 'recipients', component: Recipients },
  { id: 'attachments', component: Attachments },
];

export const formSchemaValidationRules = () => ({
  title: {
    required: t('share-report.report-name.required', 'Report name is required'),
  },
  dashboards: {
    required: t('share-report.dashboard.dashboard-required-field', 'Dashboard is required'),
  },
  replyTo: {
    validate: (val: string | undefined) => {
      if (!val) {
        return true;
      }
      return isEmail(val) || t('share-report.email-configuration.invalid-email', 'Invalid email');
    },
  },
  recipients: {
    required: t('share-report.recipients.recipients-required-field', 'Recipients are required'),
  },
});

const defaultReport: Partial<ReportBaseV2> = {
  message: DEFAULT_EMAIL_MESSAGE,
  addDashboardUrl: true,
  addDashboardImage: false,
  pdfOptions: {
    orientation: 'landscape',
    layout: 'grid',
    scaleFactor: defaultZoom,
  },
};

export default function ReportForm({
  report,
  dashboard,
}: {
  report?: Partial<ReportBaseV2>;
  dashboard?: DashboardScene;
}) {
  const styles = useStyles2(getStyles);
  const [dashboards, setDashboards] = useState<SelectDashboardScene[]>([]);

  const form = useForm<ReportBaseV2>({
    mode: 'onChange',
    defaultValues: {
      ...defaultReport,
      ...report,
    },
  });
  const { append: addDashboard, remove: removeDashboard } = useFieldArray({
    control: form.control,
    name: 'dashboards',
  });

  useEffect(() => {
    form.setFocus('title');
  }, [form, form.setFocus]);

  useEffectOnce(() => {
    const dashboardScene = createSelectDashboardScene(dashboard);
    setDashboards([dashboardScene]);
  });

  const onRemoveDashboardClick = (dashboard: SelectDashboardScene) => {
    const index = dashboards.findIndex((d) => d === dashboard);
    removeDashboard(index);
    setDashboards((prevState) => prevState.filter((d) => d !== dashboard));
  };

  const createSelectDashboardScene = (dashboard?: DashboardScene) => {
    return new SelectDashboardScene({
      key: dashboard?.state.key,
      uid: dashboard?.state.uid,
      title: dashboard?.state.title,
      $timeRange: dashboard?.state.$timeRange?.clone(),
      $variables: dashboard?.state.$variables?.clone(),
      variableControls: [new VariableValueSelectors({ layout: 'vertical' }), new SceneDataLayerControls()],
      onRemoveClick: onRemoveDashboardClick,
    });
  };

  const onAddDashboardClick = () => {
    const customScene = createSelectDashboardScene();

    setDashboards((prevState) => [...prevState, customScene]);
    addDashboard({
      uid: undefined,
      key: customScene.state.key,
    });
  };

  const onSubmit = (data: ReportBaseV2) => {
    // const dashboardsDTO = getDashboardsDTO(dashboards);
    // console.log({ ...data, dashboards: dashboardsDTO });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={styles.form}
        data-testid={selectors.components.ReportFormDrawer.container}
      >
        <fieldset disabled={!canEditReport} className={styles.container}>
          <Field
            label={t('share-report.report-name.label', 'Report name')}
            required
            error={form.formState.errors.title?.message}
            invalid={!!form.formState.errors.title}
          >
            <Input
              {...form.register('title', formSchemaValidationRules().title)}
              placeholder={report?.title}
              id="report-name"
              type="text"
              autoComplete="off"
              name={'name'}
            />
          </Field>
          <Divider spacing={0} />
          <div className={styles.reportSectionContainer}>
            <Stack direction="column" flex={1}>
              {REPORT_FORM_SECTIONS.map((section, index) => (
                <div key={section.id}>
                  <section.component dashboards={dashboards} onAddDashboard={onAddDashboardClick} />
                  {index < REPORT_FORM_SECTIONS.length - 1 && <Divider />}
                </div>
              ))}
            </Stack>
          </div>
          <Divider />
          <ReportActions />
        </fieldset>
      </form>
    </FormProvider>
  );
}

function ReportActions() {
  return (
    <Stack justifyContent="space-between">
      <Stack gap={1}>
        <Button variant="secondary" fill="outline" icon="ellipsis-v" />
        <Button variant="secondary" fill="outline">
          {t('share-report.send-preview.button', 'Send preview')}
        </Button>
      </Stack>
      <Stack gap={1}>
        <Button type="submit" variant="primary" data-testid={selectors.components.ReportFormDrawer.submitButton}>
          {t('share-report.schedule-report.button', 'Schedule report')}
        </Button>
        <Button variant="secondary">{t('share-report.save-draft.button', 'Save draft')}</Button>
      </Stack>
    </Stack>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    form: css({
      height: '100%',
    }),
    container: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }),
    reportSectionContainer: css({
      height: '100%',
      marginTop: theme.spacing(2),
    }),
  };
};
